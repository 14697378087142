import html2canvas from "html2canvas";

export function downloadAsImage(
  element,
  filename = "download.png",
  options = {},
) {
  if (!element) return;

  const {
    scale = 6,
    backgroundColor = "#ffffff",
    windowWidth = 1000,
    windowHeight = 900,
    x = 0,
    y = 0,
    width,
    height,
  } = options;
  // Add capture class before rendering
  element.classList.add("capturing-image");

  return document.fonts.ready
    .then(() => {
      return html2canvas(element, {
        scale,
        backgroundColor,
        logging: false,
        useCORS: true,
        windowWidth,
        windowHeight,
        allowTaint: true,
        letterRendering: true,
        x,
        y,
        width,
        height,
      });
    })
    .then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch((error) => {
      console.error("Error downloading image:", error);
    })
    .finally(() => {
      // Remove capture class after we're done
      element.classList.remove("capturing-image");
    });
}
