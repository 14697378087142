async function fetchData(url, options = {}) {
  try {
    const response = await fetch(url, options);
    if (!response.ok) throw new Error("Network response was not ok");
    return response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    return null;
  }
}

function truncateString(str, num) {
  return str.length > num ? `${str.slice(0, num)}...` : str;
}

export function configureStudyTriggers(args) {
  const metadataUrl = args.metadataUrl;
  const searchMetadataUrl = args.searchMetadataUrl;

  return {
    conditions: args.triggers,
    eventTypes: args.eventTypes,
    showConditionModal: false,
    modalData: null,
    modalIndex: null,
    serializedConditions: "",
    search: { results: [], count: 0 },
    searching: false,
    initialState: true,
    smallerText: false, // some customers have really long event names, so we'll shrink the font a bit
    init() {
      this.selectedEventIds = new Set(this.conditions.map((c) => c.eventId));
      this.conditions = this.conditions.map((condition) => {
        const name = this.eventTypes[condition.eventId] || "Select event...";
        return this.createCondition({
          ...condition,
          name,
          truncatedName: truncateString(name, 40),
          triggerType: condition.threshold === 1 ? "once" : "count",
        });
      });
      if (this.conditions.length === 0) {
        this.addCondition();
      }
      if (Object.values(this.eventTypes).some((name) => name.length > 45)) {
        this.smallerText = true;
      }
      this.modalData = this.conditions[0];
      this.updateSerializedConditions();
    },
    addCondition() {
      this.conditions.push(this.createCondition());
      this.updateSerializedConditions();
    },
    removeCondition(index) {
      const [removed] = this.conditions.splice(index, 1);
      if (removed && removed.eventId) {
        this.selectedEventIds.delete(removed.eventId);
      }
      this.updateSerializedConditions();
    },
    selectEvent(condition, name, id) {
      if (this.isSelected(id)) return;
      if (condition.eventId) {
        this.selectedEventIds.delete(condition.eventId);
      }

      this.selectedEventIds.add(id);
      Object.assign(
        condition,
        this.createCondition({
          name,
          truncatedName: truncateString(name, 40),
          eventId: id,
          open: false,
        }),
      );
      this.updateSerializedConditions();
    },
    isSelected(id) {
      return this.selectedEventIds.has(id);
    },
    toggleOperator(index) {
      const condition = this.conditions[index];
      condition.operator = condition.operator === "AND" ? "OR" : "AND";
    },
    anyMoreEventsToSelect() {
      return Object.keys(this.eventTypes).length > this.conditions.length;
    },
    showSearching() {
      if (!this.modalData.metadataRegex) {
        this.clearSearch();
        this.initialState = true;
      } else {
        this.search.results = this.search.results || [];
        this.searching = true;
      }
    },
    async searchMetadata() {
      const eventId = this.modalData.eventId;
      const metadataKey = this.modalData.metadataKey;
      const metadataRegex = this.modalData.metadataRegex;

      if (!metadataRegex) {
        this.clearSearch();
        return;
      }

      this.searching = true;
      this.initialState = false;
      const results = await fetchData(searchMetadataUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_type_id: eventId,
          search_key: metadataKey,
          search_value: metadataRegex,
        }),
      });
      if (results) {
        this.search.results = results.matching_values;
        this.search.count = results.count;
      }
      this.searching = false;
    },
    selectMetadataKey(key) {
      this.modalData.metadataKey = key;
      this.modalData.metadataRegex = "";
      this.clearSearch();
      this.initialState = true;
    },
    // handle modal open/close
    async openModal(index) {
      this.initialState = true;
      this.modalData = { ...this.conditions[index] };
      if (this.modalData.triggerType === "once") {
        this.modalData.triggerCount = 5;
      } else {
        this.modalData.triggerCount = this.modalData.threshold;
      }
      this.modalIndex = index;
      this.showConditionModal = true;

      if (!this.modalData.metadataKeys) {
        this.modalData.loadingMetadataKeys = true;
        await this.loadMetadataKeys(index);
      }
    },
    async loadMetadataKeys(index) {
      const eventId = this.modalData.eventId;
      const metadataKeys = await fetchData(
        `${metadataUrl}?event_type_id=${eventId}`,
      );
      if (metadataKeys) {
        this.modalData.loadingMetadataKeys = false;
        this.modalData.metadataKeys = metadataKeys;
        this.conditions[index].metadataKeys = metadataKeys;
      }
    },
    closeModal() {
      this.showConditionModal = false;
      this.clearSearch();
    },
    closeAndSaveModal() {
      this.showConditionModal = false;

      if (this.modalData.triggerType === "once") {
        this.modalData.threshold = 1;
      } else {
        this.modalData.threshold = parseInt(this.modalData.triggerCount, 10);
      }
      if (!this.modalData.metadataKey) {
        delete this.modalData.metadataRegex;
      }
      this.clearSearch();

      this.conditions[this.modalIndex] = this.modalData;
      this.updateSerializedConditions();
    },
    clearSearch() {
      this.search.results = [];
      this.searching = false;
    },
    createCondition(overrides = {}) {
      return {
        name: "Select event...",
        truncatedName: "Select event...",
        eventId: 0,
        open: false,
        operator: "OR",
        triggerType: "once",
        metadataKeys: null, // metadata keys for the selected event loaded async
        metadataKey: null,
        metadataRegex: null,
        threshold: 1,
        ...overrides,
      };
    },
    updateSerializedConditions() {
      this.serializedConditions = JSON.stringify(
        this.conditions.filter((c) => c.eventId),
      );
    },
  };
}
